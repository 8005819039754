import React from "react";

export const HEADLINE_SIZES = {
  X_LARGE: "xLarge",
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

type SizeType = (typeof HEADLINE_SIZES)[keyof typeof HEADLINE_SIZES];

type HeadlineProps = {
  size: SizeType;
  color: string;
  children: React.ReactNode;
};

const SIZE_STYLES: Record<SizeType, string> = {
  [HEADLINE_SIZES.X_LARGE]: "text-4xl",
  [HEADLINE_SIZES.LARGE]: "text-3xl",
  [HEADLINE_SIZES.MEDIUM]: "text-2xl",
  [HEADLINE_SIZES.SMALL]: "text-lg",
  [HEADLINE_SIZES.X_SMALL]: "text-base",
};

const Headline = ({
  size = HEADLINE_SIZES.SMALL,
  className = "",
  color = "text-primary",
  children,
  ...props
}: React.ComponentPropsWithoutRef<"h1"> & Partial<HeadlineProps>) => {
  const classes = `m-0 p-0 font-manrope font-bold ${SIZE_STYLES[size]} ${color} ${className}`;

  switch (size) {
    case HEADLINE_SIZES.X_LARGE:
      return (
        <h1 className={classes} {...props}>
          {children}
        </h1>
      );

    case HEADLINE_SIZES.LARGE:
      return (
        <h2 className={classes} {...props}>
          {children}
        </h2>
      );

    case HEADLINE_SIZES.MEDIUM:
      return (
        <h3 className={classes} {...props}>
          {children}
        </h3>
      );

    case HEADLINE_SIZES.SMALL:
      return (
        <h4 className={classes} {...props}>
          {children}
        </h4>
      );

    case HEADLINE_SIZES.X_SMALL:
      return (
        <h5 className={classes} {...props}>
          {children}
        </h5>
      );
  }
};

export default Headline;
