import * as React from "react";
const SvgCalendarBlank = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "m5.832 14.168 4.089.031M10 10.834h4.168M17.503 7.499H2.497M6.665 2.08v2.501M13.335 2.08v2.501",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M15.002 17.503H4.998a2.5 2.5 0 0 1-2.501-2.5V5.831a2.5 2.5 0 0 1 2.5-2.501h10.005a2.5 2.5 0 0 1 2.501 2.5v9.171a2.5 2.5 0 0 1-2.501 2.501Z",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgCalendarBlank;
