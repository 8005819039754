import * as React from "react";
const SvgGuidesActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M17.5 13.89V4.167a1.666 1.666 0 0 0-2.194-1.581L10.527 4.18a1.666 1.666 0 0 1-1.054 0l-4.78-1.593A1.667 1.667 0 0 0 2.5 4.168v9.721c0 .718.46 1.354 1.14 1.581l5.833 1.945c.342.114.712.114 1.054 0l5.833-1.945a1.667 1.667 0 0 0 1.14-1.58ZM10.6 7.5a.6.6 0 1 0-1.2 0v6.667a.6.6 0 0 0 1.2 0V7.5Z",
  stroke: "none",
  fill: "#0517F8"
}));
export default SvgGuidesActive;
