import Label, { LABEL_SIZES } from "@shared/ui/Label";

export const COUNTER_SIZES = {
  X_LARGE: "xLarge",
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

export type CounterSizeType =
  (typeof COUNTER_SIZES)[keyof typeof COUNTER_SIZES];

const SIZE_STYLES: Record<CounterSizeType, string> = {
  [COUNTER_SIZES.X_LARGE]: "w-2xl h-2xl",
  [COUNTER_SIZES.LARGE]: "w-lg h-lg",
  [COUNTER_SIZES.MEDIUM]: "w-md h-md",
  [COUNTER_SIZES.SMALL]: "w-sm h-sm",
  [COUNTER_SIZES.X_SMALL]: "w-xs h-xs",
};

const TEXT_SIZE_STYLES: Record<CounterSizeType, string> = {
  [COUNTER_SIZES.X_LARGE]: "text-xs",
  [COUNTER_SIZES.LARGE]: "text-2xs",
  [COUNTER_SIZES.MEDIUM]: "",
  [COUNTER_SIZES.SMALL]: "",
  [COUNTER_SIZES.X_SMALL]: "",
};

type NotificationCounterPropsType = {
  count?: number;
  size?: CounterSizeType;
  className?: string;
  isSizedCounter?: boolean;
};

const NotificationCounter = ({
  count = null,
  size = COUNTER_SIZES.X_LARGE,
  className = "",
  isSizedCounter = false,
}: NotificationCounterPropsType) => {
  const commonClasses: string =
    "flex items-center justify-center rounded-full bg-danger";

  if (count && !isSizedCounter) {
    return (
      <div className={`${commonClasses} px-xs py-3xs ${className}`}>
        <Label size={LABEL_SIZES.X_SMALL} color="text-inverse">
          {count > 999 ? "999+" : count}
        </Label>
      </div>
    );
  }

  return (
    <div className={`${commonClasses} ${SIZE_STYLES[size]} ${className}`}>
      {count != null &&
        (size === COUNTER_SIZES.X_LARGE || size === COUNTER_SIZES.LARGE) && (
          <span
            className={`font-manrope font-bold text-inverse ${TEXT_SIZE_STYLES[size]}`}
          >
            {count}
          </span>
        )}
    </div>
  );
};

export default NotificationCounter;
