import * as React from "react";
const SvgAiNotetakerActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M5.833 17.5A3.333 3.333 0 0 0 2.5 14.166a3.333 3.333 0 0 0 3.333-3.333 3.333 3.333 0 0 0 3.334 3.333A3.333 3.333 0 0 0 5.833 17.5Z",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.668 17.5h2.917c1.15 0 2.083-.933 2.083-2.083v-8.44a2.5 2.5 0 0 0-.732-1.768l-1.977-1.977a2.5 2.5 0 0 0-1.767-.732h-5.94c-1.151 0-2.084.933-2.084 2.083V7.5",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.647 6.667H13.75c-.69 0-1.25-.56-1.25-1.25V2.52",
  stroke: "#0517F8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAiNotetakerActive;
