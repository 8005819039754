import * as React from "react";
const SvgLoading = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  stroke: "currentColor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2,
  d: "M16 23.493V28m-5.307-6.693L7.52 24.48M8.507 16H4m3.52-8.48 3.173 3.173M16 8V4m6.36 5.64 2.12-2.12M28 16h-2m-1.52 8.48-.707-.707"
}));
export default SvgLoading;
