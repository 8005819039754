import Tooltip from "@shared/ui/Tooltip";
import React from "react";
import { Place, Offset } from "react-tooltip";

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  OUTLINE: "outline",
  LINK: "link",
  DANGER_LINK: "dangerLink",
  DANGER: "danger",
} as const;

export const BUTTON_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
} as const;

export type ButtonVariantType =
  (typeof BUTTON_VARIANTS)[keyof typeof BUTTON_VARIANTS];

type ButtonSizeType = (typeof BUTTON_SIZES)[keyof typeof BUTTON_SIZES];

type ButtonPropsType = {
  variant: ButtonVariantType;
  size: ButtonSizeType;
  fullWidth: boolean;
  text: string;
  leadingIcon: React.JSX.Element;
  trailingIcon: React.JSX.Element;
  className: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  tooltipId: string;
  tooltipText: string;
  tooltipPosition: Place;
  tooltipClassName?: string;
  tooltipOffset?: Offset;
};

const SIZE_STYLES: Record<ButtonSizeType, string> = {
  [BUTTON_SIZES.NORMAL]: "h-4xl",
  [BUTTON_SIZES.SMALL]: "h-3xl",
};

const VARIANT_STYLES: Record<ButtonVariantType, string> = {
  [BUTTON_VARIANTS.PRIMARY]:
    "border-0 text-inverse bg-brand-dark active:bg-secondary hover:bg-secondary disabled:bg-disabled",
  [BUTTON_VARIANTS.SECONDARY]:
    "border-0 text-brand bg-brand-lightest active:bg-brand-light hover:bg-brand-light disabled:text-inverse disabled:bg-disabled",
  [BUTTON_VARIANTS.OUTLINE]:
    "text-primary bg-transparent border border-solid border-primary active:text-brand active:border-brand hover:text-brand hover:border-brand disabled:text-disabled disabled:border-primary",
  [BUTTON_VARIANTS.LINK]:
    "h-auto border-0 p-0 bg-transparent text-brand hover:text-primary disabled:text-disabled",
  [BUTTON_VARIANTS.DANGER_LINK]:
    "h-auto border-0 p-0 bg-transparent text-danger disabled:text-disabled",
  [BUTTON_VARIANTS.DANGER]:
    "text-danger bg-transparent border border-solid border-danger active:bg-danger active:text-inverse hover:bg-danger hover:text-inverse disabled:text-disabled disabled:bg-transparent disabled:border-primary",
};

const commonClasses: string =
  "relative flex items-center justify-center font-manrope text-xs font-bold gap-sm rounded-lg cursor-pointer disabled:cursor-default transition-all duration-200";

const getHorizontalPadding = (
  variant: ButtonVariantType,
  text: string,
  size: ButtonSizeType,
): string => {
  if (
    variant === BUTTON_VARIANTS.LINK ||
    variant === BUTTON_VARIANTS.DANGER_LINK
  ) {
    return "px-0";
  } else if (text.length === 0) {
    // Icon only button
    return size === BUTTON_SIZES.NORMAL ? "px-md" : "px-sm";
  } else {
    return size === BUTTON_SIZES.NORMAL ? "px-lg" : "px-md";
  }
};

const Button = ({
  variant = BUTTON_VARIANTS.PRIMARY,
  size = BUTTON_SIZES.NORMAL,
  fullWidth = false,
  text = "",
  leadingIcon = null,
  trailingIcon = null,
  className = "",
  onClick = () => {},
  tooltipId = "",
  tooltipText = "",
  tooltipPosition = "bottom",
  tooltipClassName = "",
  tooltipOffset = null,
  ...props
}: React.ComponentPropsWithoutRef<"button"> & Partial<ButtonPropsType>) => {
  const buttonClasses: string = `${commonClasses} ${
    fullWidth ? "w-full" : ""
  } ${
    variant !== BUTTON_VARIANTS.LINK && variant !== BUTTON_VARIANTS.DANGER_LINK
      ? SIZE_STYLES[size]
      : ""
  } ${VARIANT_STYLES[variant]} ${getHorizontalPadding(
    variant,
    text,
    size,
  )} ${className}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      data-for={tooltipId}
      data-tip={tooltipText}
      {...props}
    >
      {leadingIcon}
      {text}
      {trailingIcon}

      {tooltipText && (
        <Tooltip
          id={tooltipId}
          place={tooltipPosition}
          offset={tooltipOffset}
          className={tooltipClassName}
        />
      )}
    </button>
  );
};

export default Button;
