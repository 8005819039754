import * as React from "react";
const Svg3DMachine = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M17.5 13.368V6.633c0-.596-.317-1.146-.833-1.444l-5.834-3.367a1.668 1.668 0 0 0-1.666 0L3.333 5.188A1.668 1.668 0 0 0 2.5 6.633v6.735c0 .596.317 1.146.833 1.444l5.834 3.367c.515.298 1.15.298 1.666 0l5.834-3.367c.515-.299.833-.849.833-1.444Z",
  stroke: "#748094",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.003 18.4V10M10 10l7.275-4.2M2.727 5.8l7.275 4.2",
  stroke: "#748094",
  strokeWidth: 1.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default Svg3DMachine;
