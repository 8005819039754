import React from "react";

export const BODY_TEXT_SIZES = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

type SizeType = (typeof BODY_TEXT_SIZES)[keyof typeof BODY_TEXT_SIZES];

type BodyTextProps = {
  size: SizeType;
  color: string;
  children: React.ReactNode;
  setRef?: (_: HTMLParagraphElement) => void;
};

const SIZE_STYLES: Record<SizeType, string> = {
  [BODY_TEXT_SIZES.LARGE]: "text-lg",
  [BODY_TEXT_SIZES.MEDIUM]: "text-base",
  [BODY_TEXT_SIZES.SMALL]: "text-sm",
  [BODY_TEXT_SIZES.X_SMALL]: "text-xs",
};

const BodyText = ({
  size = BODY_TEXT_SIZES.SMALL,
  color = "text-primary",
  className = "",
  children,
  setRef,
  ...props
}: React.ComponentPropsWithoutRef<"p"> & Partial<BodyTextProps>) => {
  const classes = `m-0 p-0 font-manrope font-medium ${SIZE_STYLES[size]} ${color} ${className}`;

  return (
    <p className={classes} {...props} ref={setRef}>
      {children}
    </p>
  );
};

export default BodyText;
