import { right } from "@popperjs/core";
import LogoutIcon from "@shared/svg/logout.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import Overlay from "@shared/ui/Overlay";
import { User } from "phosphor-react";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import useOnClickOutside from "use-onclickoutside";

import { generateInitials } from "~/utils";

// Variants of avatar
// 1. regular
// 2. small
// 3. extra-small

const Avatar = ({
  className = "",
  variant = "regular",
  name = "",
  email = "",
  showTooltip = false,
  showOverlay = false,
  role = "",
  oemName = "",
  logout = () => {},
}) => {
  const classes = [
    `avatar ${className}`,
    variant ? `avatar--${variant}` : "",
  ].join(" ");
  const userName = name;

  const [showAvatarOverlay, setShowAvatarOverlay] = useState(false);
  const avatarRef = useRef();
  const overlayRef = useRef();

  const { messages } = useIntl();

  useOnClickOutside(overlayRef, () => setShowAvatarOverlay(false));

  return (
    <>
      <span
        className={`${classes} ${showOverlay ? "cursor-pointer" : ""}`}
        title={showTooltip ? userName : ""}
        onClick={() => showOverlay && setShowAvatarOverlay(!showAvatarOverlay)}
        ref={avatarRef}
      >
        {generateInitials(userName)}
      </span>
      {showOverlay && (
        <Overlay
          arrowClasses="before:bg-primary before:shadow-xl"
          className="ml-sm bg-primary rounded-md p-lg shadow-xl w-fit z-10"
          containerRef={document.body}
          offset={[-40, 20]}
          placement={right}
          showOverlay={showAvatarOverlay}
          targetRef={avatarRef}
        >
          <div className="relative" ref={overlayRef}>
            <div className="absolute w-9xl h-4xl bottom-0 -left-20 cursor-pointer" />
            <Label color="text-primary" size={LABEL_SIZES.MEDIUM}>
              {name}
            </Label>
            <BodyText
              color="text-tertiary"
              className="mt-3xs"
              size={BODY_TEXT_SIZES.X_SMALL}
            >
              {messages.settings?.roles[role].label} • {oemName}
            </BodyText>
            <BodyText color="text-tertiary" size={BODY_TEXT_SIZES.X_SMALL}>
              {email}
            </BodyText>
            <div className="w-full h-px bg-tertiary my-lg min-w-44xl" />
            <div
              className="flex cursor-pointer justify-between space-x-sm items-center rounded-md hover:bg-accent"
              onClick={() => logout()}
            >
              <div className="flex space-x-sm items-center">
                <LogoutIcon className="icon w-3xl h-3xl flex-shrink-0" />
                <BodyText size={BODY_TEXT_SIZES.SMALL} color="text-secondary">
                  {messages?.login?.["signOut"]}
                </BodyText>
              </div>
            </div>
          </div>
        </Overlay>
      )}
    </>
  );
};

export const UserAvatar = React.memo(
  ({
    className,
    imageSrc,
    name,
    useAvatar,
    variant,
    nameClassName = "",
    ...props
  }) => {
    const classes = ["user-avatar-container", className || ""].join(" ");
    return (
      <div className={classes} {...props}>
        <span className={`user-avatar ${name ? "mr-sm" : ""}`}>
          {useAvatar && name ? (
            variant ? (
              <Avatar name={name} variant={variant} />
            ) : (
              <Label
                size={LABEL_SIZES.X_SMALL}
                color="text-brand"
                className="font-medium"
              >
                {generateInitials(name)}
              </Label>
            )
          ) : imageSrc ? (
            <img src={imageSrc} alt={name} />
          ) : (
            <User size={12} />
          )}
        </span>
        {name && (
          <BodyText size={BODY_TEXT_SIZES.X_SMALL} className={nameClassName}>
            {name}
          </BodyText>
        )}
      </div>
    );
  },
);

export const DropdownAvatar = ({
  name,
  className,
  variant = "extra-small",
}) => {
  const classes = [
    `avatar ${className}`,
    variant ? `avatar--${variant}` : "",
  ].join(" ");

  return <span className={classes}>{generateInitials(name)}</span>;
};

export default Avatar;
