import * as React from "react";
const Svg3DMachineActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M17.5 6.633v6.735c0 .595-.317 1.145-.833 1.444l-5.834 3.367c-.06.035-.12.065-.183.091v-7.895l6.837-3.947c.009.067.013.136.013.205Zm-8.15 3.742v7.895a1.662 1.662 0 0 1-.183-.09l-5.834-3.368a1.666 1.666 0 0 1-.833-1.444V6.633c0-.07.004-.138.013-.205l6.837 3.947ZM3.162 5.302 10 9.25l6.837-3.948a1.677 1.677 0 0 0-.17-.113l-5.834-3.367a1.668 1.668 0 0 0-1.666 0L3.333 5.188c-.06.035-.117.073-.17.114Z",
  stroke: "none",
  fill: "#0517F8"
}));
export default Svg3DMachineActive;
