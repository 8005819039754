import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input } from "@shared/ui/Inputs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ContentLoading from "../ContentLoading";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LoginForm = ({ modifier, callback = () => {}, color }) => {
  const { messages: msg } = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    callback(data, setError);
  };

  const onError = () => {
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      clearErrors("root");
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, [errors.root]);

  const emailField = register("email", {
    required: {
      value: true,
      message: msg?.common?.errors.emailRequired,
    },
    pattern: {
      value: emailPattern,
      message: msg?.login?.email.invalidEmail,
    },
  });

  const passwordField = register("password", {
    required: {
      value: true,
      message: msg?.login?.password.invalidPassword,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <div className="form-authHead">
        <Headline size={HEADLINE_SIZES.SMALL} className="mb-sm">
          {msg.login?.welcome}
        </Headline>
        <p className="form-authHeadLead">{msg.login?.subtitle}</p>
      </div>

      <Input
        keyId="email"
        label={msg.login?.email.label}
        name="email"
        type="text"
        placeholder={msg.login?.email.placeholder}
        {...emailField}
        setRef={emailField.ref}
        errorMessage={errors?.email?.message}
        customInputClasses={
          errors?.email?.message
            ? "bg-primary border-danger focus:bg-primary focus:border-danger"
            : watch("email")
            ? "bg-primary border-primary"
            : "bg-accent border-transparent"
        }
      />

      <div className="mt-xl mb-md">
        <Input
          keyId="password"
          label={msg.login?.password.label}
          name="password"
          type="password"
          placeholder={msg.login?.password.placeholder}
          {...passwordField}
          setRef={passwordField.ref}
          errorMessage={errors?.password?.message}
          customInputClasses={
            errors?.password?.message
              ? "bg-primary border-danger focus:bg-primary focus:border-danger"
              : watch("password")
              ? "bg-primary border-primary"
              : "bg-accent border-transparent"
          }
        />
      </div>
      <div>
        <Link to={`/forgot-password${modifier ? "/" + modifier : ""}`}>
          <Button
            variant={BUTTON_VARIANTS.LINK}
            type="button"
            text={msg.login?.forgotPassword.title}
          />
        </Link>
      </div>

      <div className="form-group auth-submit">
        <button
          className={`c-button primary-icon-btn-v2 ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            setLoading(true);
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading ? <ContentLoading /> : `${msg.login?.loginLabel}`}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
