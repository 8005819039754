import { useMutation } from "@apollo/client";

import {
  UPDATE_OWN_OEM_TICKET_MUTATION,
  CREATE_OWN_OEM_TICKET_MUTATION,
  Ticket,
  ADD_OWN_OEM_PART_TO_TICKET_MUTATION,
  ADD_TICKET_TIME_TRACKER_LOG_MUTATION,
  NOTIFY_ON_INTERNAL_POST_MUTATION,
  REMOVE_OWN_OEM_PART_FROM_TICKET_MUTATION,
  REMOVE_TIME_TRACKER_LOG_FROM_TICKET_MUTATION,
  UPDATE_TICKET_TIME_TRACKER_LOG_MUTATION,
  UPDATE_TICKET_KANBAN_OWN_OEM_TICKET_MUTATION,
  GET_OWN_OEM_TICKET_BY_ID,
  ADD_TICKET_ASSIGNEE_MUTATION,
  REMOVE_TICKET_ASSIGNEE_MUTATION,
  ADD_TICKET_FOLLOWER_MUTATION,
  REMOVE_TICKET_FOLLOWER_MUTATION,
  DELETE_OWN_OEM_TICKET_MUTATION,
  UPDATE_TITLE_TICKET_MUTATION,
  UPDATE_DESCRIPTION_TICKET_MUTATION,
  ADD_TICKET_RESOURCE_MUTATION,
  REMOVE_TICKET_RESOURCES_MUTATION,
  LINK_TICKETS,
  UNLINK_TICKETS,
} from "../../api";
import client from "../../apollo/_client";

export const updateTicket = () => {
  const [updateOwnOemTicket, { error, loading }] = useMutation(
    UPDATE_OWN_OEM_TICKET_MUTATION,
  );

  return {
    mutation: (data) =>
      updateOwnOemTicket({
        variables: {
          input: {
            ...data,
          },
        },

        update(cache, { data: { updateOwnOemTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              listOwnOemOpenTickets(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketListData,
                  fragmentName: "TicketListData",
                });

                return [newRef, ...existingRefs];
              },
            },
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemAllTickets",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemOpenTickets",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemTickets",
          });

          if (data.status) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "getOwnOemAnalytics",
            });
          }
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const addResource = () => {
  const [addTicketResource, { error, loading }] = useMutation(
    ADD_TICKET_RESOURCE_MUTATION,
  );

  return {
    addResourceMutation: (data) =>
      addTicketResource({
        variables: {
          input: {
            ...data,
          },
        },

        update(cache, { data: { addTicketResource: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [newRef, ...existingRefs];
              },
            },
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemTickets",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemResources",
          });

          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const removeResources = () => {
  const [removeTicketResources, { error, loading }] = useMutation(
    REMOVE_TICKET_RESOURCES_MUTATION,
  );

  return {
    removeResourcesMutation: (data) =>
      removeTicketResources({
        variables: {
          input: {
            ...data,
          },
        },

        update(cache, { data: { removeTicketResources: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [newRef, ...existingRefs];
              },
            },
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemTickets",
          });

          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemResources",
          });

          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const createTicket = () => {
  const [createOwnOemTicket, { error: { networkError } = {}, loading }] =
    useMutation(CREATE_OWN_OEM_TICKET_MUTATION);

  return {
    mutation: (data) =>
      createOwnOemTicket({
        variables: {
          input: {
            ...data,
          },
        },
        update(cache, { data: { createOwnOemTicket: ref } }) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemKanbanTickets",
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemTickets",
          });
          cache.gc();
        },
      }),
    loading,
    serverErrors: networkError,
  };
};

export const notifyOnInternalPost = () => {
  const [notifyOnInternalNotePost, { error: { networkError } = {}, loading }] =
    useMutation(NOTIFY_ON_INTERNAL_POST_MUTATION);

  return {
    mutation: ({ ticketId, mentionedUsers, message }) =>
      notifyOnInternalNotePost({
        variables: {
          input: {
            ticketId,
            mentionedUsers,
            message,
          },
        },
      }),
    loading,
    serverErrors: networkError,
  };
};

export const assignPart = () => {
  const [assign, { loading: assigningPart, error }] = useMutation(
    ADD_OWN_OEM_PART_TO_TICKET_MUTATION,
  );

  return {
    assigningPart,
    error,
    assignPartToTicket: async (input) =>
      assign({
        variables: { input },
        skip: !input?.ticketId || !input?.inventoryParts?.length,
        update(cache, { data: { assignOwnOemInventoryPartsToTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
  };
};

export const removePart = () => {
  const [remove, { loading: removingPart, error }] = useMutation(
    REMOVE_OWN_OEM_PART_FROM_TICKET_MUTATION,
  );

  return {
    removingPart,
    error,
    removePartFromTicket: async (input) =>
      remove({
        variables: { input },
        skip: !input?.ticketId || !input?.partId,
        update(cache, { data: { removeOwnOemInventoryPartFromTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
  };
};

export const addTimeTrackerLog = () => {
  const [add, { loading: addingTimeLog, error }] = useMutation(
    ADD_TICKET_TIME_TRACKER_LOG_MUTATION,
  );

  return {
    addingTimeLog,
    error,
    addTimeLog: async (input) =>
      add({
        variables: { input },
        skip:
          !input?.ticketId || !Object.keys(input?.timeTrackerLog || {}).length,
        update(cache, { data: { addTimeTrackerLog: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });
          cache.gc();
        },
      }),
  };
};

export const updateTimeTrackerLog = () => {
  const [update, { loading: updatingTimeLog, error }] = useMutation(
    UPDATE_TICKET_TIME_TRACKER_LOG_MUTATION,
  );

  return {
    updatingTimeLog,
    updateTimeLogError: error,
    updateTimeLog: async (input) =>
      update({
        variables: { input },
        skip: !input?.ticketId || !input.timeLogId,
        update(cache, { data: { updateTimeTrackerLog: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
  };
};

export const removeTimeTrackerLog = () => {
  const [remove, { loading: removingTimeLog, error }] = useMutation(
    REMOVE_TIME_TRACKER_LOG_FROM_TICKET_MUTATION,
  );

  return {
    removingTimeLog,
    error,
    removeTimeLogFromTicket: async (input) =>
      remove({
        variables: { input },
        skip: !input?.ticketId || !input?.timeLogId,
        update(cache, { data: { removeTimeTrackerLogFromTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
        },
      }),
  };
};

export const updateTitleTicket = () => {
  const [update, { loading: updatingTitle, error }] = useMutation(
    UPDATE_TITLE_TICKET_MUTATION,
  );

  return {
    updatingTitle,
    updateTitleError: error,
    updateTitle: async (input) =>
      update({
        variables: { input },
        skip: !input?.ticketId || !input.title,
        update(cache, { data: { updateTitleTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });
          cache.gc();
        },
      }),
  };
};

export const updateDescriptionTicket = () => {
  const [update, { loading: updatingDescription, error }] = useMutation(
    UPDATE_DESCRIPTION_TICKET_MUTATION,
  );

  return {
    updatingDescription,
    updateDescriptionError: error,
    updateDescription: async (input) =>
      update({
        variables: { input },
        skip: !input?.ticketId || !input.description,
        update(cache, { data: { updateDescriptionTicket: ref } }) {
          cache.modify({
            id: cache.identify({ __typename: "Ticket", id: ref._id }),
            fields: {
              getOwnOemTicketById(existingRefs = []) {
                const newRef = cache.writeFragment({
                  data: ref,
                  fragment: Ticket.fragments.TicketSingleData,
                  fragmentName: "TicketSingleData",
                });

                return [...existingRefs, newRef];
              },
            },
          });
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });
          cache.gc();
        },
      }),
  };
};

export const useWorkOrderKanbanMutation = () => {
  const [update] = useMutation(UPDATE_TICKET_KANBAN_OWN_OEM_TICKET_MUTATION, {
    refetchQueries: ["listOwnOemAllTickets"],
  });

  return {
    workOrderKanbanMutation: async (input) => {
      return update({
        variables: {
          input,
        },
      });
    },
  };
};

export const updateTicketCacheOnProcedureAttached = async (
  id,
  procedure,
  addedBy,
) => {
  const ticket = client.readQuery({
    query: GET_OWN_OEM_TICKET_BY_ID,
    variables: { id },
  });
  if (!ticket) return false;

  const { getOwnOemTicketById: cachedTicket } = ticket;
  if (cachedTicket) {
    const cloned = JSON.parse(JSON.stringify(cachedTicket));
    cloned.procedures = cloned.procedures || [];
    cloned.procedures.push({
      procedure,
      addedBy,
    });

    client.writeQuery({
      query: GET_OWN_OEM_TICKET_BY_ID,
      variables: { id },
      data: {
        getOwnOemTicketById: cloned,
      },
    });
    return true;
  }

  return false;
};

export const useAddTicketAssignee = () => {
  const [addTicketAssignee, { error, loading }] = useMutation(
    ADD_TICKET_ASSIGNEE_MUTATION,
  );

  return {
    mutation: (data) =>
      addTicketAssignee({
        variables: {
          input: {
            ...data,
          },
        },
      }),
    loading,
    error,
  };
};

export const useRemoveTicketAssignee = () => {
  const [removeTicketAssignee, { error, loading }] = useMutation(
    REMOVE_TICKET_ASSIGNEE_MUTATION,
  );

  return {
    mutation: (data) =>
      removeTicketAssignee({
        variables: {
          input: {
            ...data,
          },
        },
      }),
    loading,
    error,
  };
};

export const useAddTicketFollower = () => {
  const [addTicketFollower, { error, loading }] = useMutation(
    ADD_TICKET_FOLLOWER_MUTATION,
  );

  return {
    mutation: (data) =>
      addTicketFollower({
        variables: {
          input: {
            ...data,
          },
        },
        update(cache, { data }) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const useRemoveTicketFollower = () => {
  const [removeTicketFollower, { error, loading }] = useMutation(
    REMOVE_TICKET_FOLLOWER_MUTATION,
  );

  return {
    mutation: (data) =>
      removeTicketFollower({
        variables: {
          input: {
            ...data,
          },
        },
        update(cache, { data }) {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "listOwnOemActivityLogs",
          });
          cache.gc();
        },
      }),
    loading,
    error,
  };
};

export const useLinkWorkOrders = () => {
  const [linkWorkOrdersFetched, { loading, error }] = useMutation(LINK_TICKETS);

  return {
    mutation: (input) =>
      linkWorkOrdersFetched({
        variables: input,
      }),
    loading,
    error,
  };
};

export const useUnLinkWorkOrders = () => {
  const [unLinkWorkOrdersFetched, { loading, error }] =
    useMutation(UNLINK_TICKETS);

  return {
    mutation: (input) =>
      unLinkWorkOrdersFetched({
        variables: input,
      }),
    loading,
    error,
  };
};

export const useDeleteOwnOemTicket = () => {
  const [remove, { loading, error }] = useMutation(
    DELETE_OWN_OEM_TICKET_MUTATION,
  );

  return {
    loading,
    error,
    removeTicket: async (data) =>
      new Promise((resolve, reject) =>
        remove({
          variables: {
            input: {
              ...data,
            },
          },
          update(cache) {
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemTickets",
            });
            cache.evict({
              id: "ROOT_QUERY",
              fieldName: "listOwnOemOpenTickets",
            });
            cache.gc();
          },
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          }),
      ),
  };
};
