import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { ErrorMessage, InputBox, InputLabel } from "@shared/ui/Inputs";
import React, { useRef } from "react";
import { v4 as UUID } from "uuid";

type InputPropsType = {
  label: string;
  labelDescription: string;
  uppercaseLabel: boolean;
  keyId: string;
  value: string | number;
  autoFocus: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  dataTip: string;
  required: boolean;
  errorMessage: string;
  inputClasses: string;
  wrapperClasses: string;
  leadingIconConfig: {
    icon: React.JSX.Element;
    className?: string;
  };
  trailingIconConfig: {
    icon: React.JSX.Element;
    className?: string;
    onClick?: () => void;
  };
  setRef: (_: HTMLInputElement) => void;
  customInputClasses?: string;
};

const Input = ({
  label = "",
  labelDescription = "",
  uppercaseLabel = false,
  keyId = "",
  value = undefined,
  autoFocus = false,
  onChange,
  dataTip = "",
  required = false,
  errorMessage = "",
  inputClasses = "",
  wrapperClasses = "",
  leadingIconConfig = null,
  trailingIconConfig = null,
  setRef = null,
  customInputClasses = "",
  ...props
}: React.ComponentPropsWithoutRef<"input"> & Partial<InputPropsType>) => {
  const idRef = useRef(keyId || UUID());

  return (
    <div className={`flex flex-col gap-sm ${wrapperClasses || ""}`}>
      {label && (
        <div className="flex justify-between">
          <InputLabel
            label={label}
            keyId={keyId || idRef?.current}
            uppercaseLabel={uppercaseLabel}
          />
          {labelDescription && (
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="no-styles"
            >
              {labelDescription}
            </BodyText>
          )}
        </div>
      )}

      <div className="relative flex items-center">
        {leadingIconConfig && (
          <span
            className={`absolute flex items-center text-secondary pl-md ${
              leadingIconConfig.className || ""
            }`}
          >
            {leadingIconConfig.icon}
          </span>
        )}
        <InputBox
          keyId={keyId || idRef?.current}
          value={value}
          autoFocus={autoFocus}
          onChange={onChange}
          dataTip={dataTip}
          required={required}
          hasError={!!errorMessage}
          className={inputClasses}
          setRef={setRef}
          customInputClasses={customInputClasses}
          {...props}
        />
        {trailingIconConfig && (
          <span
            className={`absolute right-0 flex items-center text-secondary pr-md cursor-pointer ${
              trailingIconConfig.className || ""
            }`}
            onClick={trailingIconConfig.onClick && trailingIconConfig.onClick}
          >
            {trailingIconConfig.icon}
          </span>
        )}
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default Input;
