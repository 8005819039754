import DottedCircleArrowIcon from "@shared/svg/dotted-circle-arrow-up.svg?react";
import LoadingIcon from "@shared/svg/loading.svg?react";
import { Checks, WarningCircle, X } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  UPLOADING: "uploading",
  PROCESSING: "processing",
} as const;

export type ToastType = (typeof TOAST_TYPES)[keyof typeof TOAST_TYPES];

type ToastPropsType = {
  message: React.ReactNode;
  type: ToastType;
};

const TOAST_TYPE_STYLES: Record<ToastType, string> = {
  [TOAST_TYPES.SUCCESS]: "bg-success-lightest border-success",
  [TOAST_TYPES.ERROR]: "bg-danger-lightest border-danger",
  [TOAST_TYPES.UPLOADING]: "bg-success-lightest border-success",
  [TOAST_TYPES.PROCESSING]: "bg-success-lightest border-success",
};

const TOAST_ICON_STYLES: Record<ToastType, string> = {
  [TOAST_TYPES.SUCCESS]: "p-sm bg-success text-white",
  [TOAST_TYPES.ERROR]: "p-sm bg-danger text-white",
  [TOAST_TYPES.UPLOADING]: "p-0 bg-transparent text-success",
  [TOAST_TYPES.PROCESSING]: "p-0 bg-transparent text-success",
};

const getToastIcon = (type: ToastType): React.JSX.Element => {
  switch (type) {
    case TOAST_TYPES.SUCCESS:
      return <Checks size={16} />;
    case TOAST_TYPES.ERROR:
      return <WarningCircle size={16} />;
    case TOAST_TYPES.UPLOADING:
      // Temporarily removed it
      // return <DottedCircleArrowIcon width="32" height="32" />;
      return (
        <span className="inline-flex animate-spin-slow">
          <LoadingIcon width="32" height="32" />
        </span>
      );
    case TOAST_TYPES.PROCESSING:
      return (
        <span className="inline-flex animate-spin-slow">
          <LoadingIcon width="32" height="32" />
        </span>
      );
    default:
      return null;
  }
};

const Toast = ({
  message = "",
  type = TOAST_TYPES.SUCCESS,
}: Partial<ToastPropsType>) => {
  const { messages } = useIntl();

  return (
    <div
      className={`flex items-center justify-between w-[20rem] p-md rounded-lg border border-solid ${TOAST_TYPE_STYLES[type]}`}
    >
      <div className="flex items-center gap-md">
        <span
          className={`flex items-center justify-center rounded-full ${TOAST_ICON_STYLES[type]}`}
        >
          {getToastIcon(type)}
        </span>
        <p className="font-manrope font-bold text-xs text-primary m-0">
          {message || messages?.common["success"]}
        </p>
      </div>
      <button
        type="button"
        className={`flex items-center justify-center p-2xs border-0 bg-transparent outline-none cursor-pointer ${
          type === TOAST_TYPES.ERROR ? "text-danger" : "text-success"
        }`}
      >
        <X size={16} />
      </button>
    </div>
  );
};

export default Toast;
