import { atom } from "recoil";

export const sortAtom = atom({
  key: "sortOptions",
  default: {
    assets: [],
    tickets: [],
    machines: [],
    customers: [],
    inventory: [],
  },
});
