import { gql, useMutation } from "@apollo/client";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { useUploadFiles } from "~/hooks";

// mutation
const _signS3Download = gql`
  mutation ($filename: String!) {
    _signS3Download(filename: $filename) {
      url
      signedRequest
    }
  }
`;

export default () => {
  const toastId = useRef(0);
  const { messages } = useIntl();
  const imageUploader = useUploadFiles({ toastRef: toastId });
  const [s3Access] = useMutation(_signS3Download);

  const [cropInstance, setCropInstance] = useState();
  const [cropData, setCropData] = useState("");
  const [loading, setLoading] = useState(true);

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    if (!fileName.endsWith(".png")) {
      const extension = fileName.match(/\.[^.]+$/)[0];
      fileName = fileName.replace(extension, ".png");
    }
    theBlob.name = fileName;
    return theBlob;
  }

  const useImageCropper = (image, options) => {
    const { width, height, quality = 1 } = options || {};
    const imageType = "image/png";
    const fillColor = "transparent";

    if (typeof width !== "number" || typeof height !== "number") {
      console.error(
        "Cropper needs both width and height as numbers, but got ",
        width,
        height,
      );
      return null;
    }
    function getCropData() {
      if (typeof cropInstance !== "undefined") {
        setCropData(
          cropInstance
            .getCroppedCanvas({
              width,
              height,
              fillColor,
              imageSmoothingEnabled: false,
              imageSmoothingQuality: "high",
            })
            .toDataURL(imageType, quality),
        );

        return new Promise((resolve, reject) => {
          cropInstance
            .getCroppedCanvas({
              width,
              height,
              fillColor,
              imageSmoothingEnabled: false,
              imageSmoothingQuality: "high",
            })
            .toBlob(
              (blob) => {
                resolve(blob);
              },
              imageType,
              quality,
            );
        });
      }
    }

    function renderCropper() {
      if (!image && loading) setLoading(false);
      if (image) {
        return (
          <div className="c-cropper">
            {loading && (
              <div className="c-spinner">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <Cropper
              src={image}
              // style={{ height: height / 2, width: "100%" }}
              style={{ height: height, width: width }}
              zoomTo={1}
              viewMode={0}
              dragMode="move"
              minCropBoxWidth={width}
              minCropBoxHeight={height}
              preview=".img-preview"
              // aspectRatio={width / height}
              background={true}
              responsive={true}
              cropBoxResizable={false}
              autoCropArea={1}
              guides={false}
              ready={() => {
                setLoading(false);
              }}
              onInitialized={(instance) => {
                setCropInstance(instance);
              }}
            />
          </div>
        );
      } else {
        return null;
      }
    }

    function preview(url) {
      return (
        <div className="form-groupImagePreview">
          <div className="form-groupImagePreviewColumn">
            <span>Live preview</span>
            <div className="u-relative">
              {loading && (
                <div className="c-spinner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
              <div
                className="img-preview"
                style={{
                  width: width / 2,
                  height: height / 2,
                  backgroundColor: fillColor,
                }}
              ></div>
            </div>
          </div>
          {url && url !== "" && (
            <div className="form-groupImagePreviewColumn">
              <span>Company logo</span>
              <div
                className="u-flex u-justify-center u-items-center"
                style={{
                  width: width / 2,
                  height: height / 2,
                  backgroundColor: fillColor,
                }}
              >
                <img className="u-width-100" src={url} />
              </div>
            </div>
          )}
        </div>
      );
    }

    function imageUpload({
      folder,
      imageFile,
      thumbnail,
      originalImageUrl,
      forceCustomPath,
    }) {
      return new Promise(async (resolve, reject) => {
        if (typeof imageFile === "undefined") {
          var filename = new URL(image).pathname.replace("/", "");

          const response = await s3Access({
            variables: {
              filename,
            },
          });

          const { signedRequest } = response.data._signS3Download;

          var newBlob = await fetch(signedRequest).then((r) => r.blob());

          let fileKey = signedRequest?.split("/")?.pop().split("?")[0];
          imageFile = blobToFile(newBlob, fileKey);
        } else {
          // push ref to the bottom of the queue otherwise React throws an error
          setTimeout(
            () =>
              (toastId.current = toast(
                <Toast
                  message={messages?.common?.preparingImages}
                  type={TOAST_TYPES.PROCESSING}
                />,
                {
                  autoClose: false,
                  progress: 0,
                  closeButton: false,
                  hideProgressBar: false,
                },
              )),
            0,
          );
        }

        var newImage = "";
        const images = imageFile
          ? [
              !thumbnail || typeof thumbnail === "string"
                ? thumbnail
                : blobToFile(thumbnail, imageFile.name),
            ]
          : [];

        imageUploader({
          files: images,
          folder,
          forceCustomPath,
          type: "s3",
        })
          .then((data) => {
            newImage = data.urls[0] || undefined;
            resolve({
              newImage,
              err:
                typeof newImage === "undefined"
                  ? "Missing image! Upload on s3 failed!"
                  : null,
            });
          })
          .catch((err) => {
            reject({
              newImage: null,
              err,
            });
          });
      });
    }

    return [getCropData, renderCropper, preview, imageUpload];
  };

  return useImageCropper;
};
