import { PIXELS_PER_MM, SLATE_EMPTY_TEXT } from "../constants";

export { base64String } from "./encode";
export { generateInitials, trimText } from "./_initials.jsx";
export * from "./_storage";
export * from "./_debounce";

export function getUrlExtension(url) {
  if (typeof url === "undefined") {
    return "";
  }

  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export const formatDate = (givenDate) => {
  const date = new Date(givenDate);

  if (date.toString() === "Invalid Date") return "";

  const fullYear = date.getFullYear();
  const fullDate = String(date.getDate()).padStart(2, "0");
  const fullMonth = String(date.getMonth() + 1).padStart(2, "0");

  return `${fullDate}-${fullMonth}-${fullYear}`;
};

export const capitalizeFirstLetter = (words) => {
  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export const secondsToHourMinute = (seconds, includeSecs = false) => {
  if (!seconds) {
    return includeSecs ? "00:00:00" : "00:00";
  }
  const hours = String(Math.floor(seconds / (60 * 60))).padStart(2, "0");
  const minutes = String(Math.floor((seconds / 60) % 60)).padStart(2, "0");
  const secs = String(seconds % 60).padStart(2, "0");

  return includeSecs ? `${hours}:${minutes}:${secs}` : `${hours}:${minutes}`;
};

export const generateTimeOptions = () => {
  var x = 30; // minutes interval
  var timeOptions = []; // time array
  var tt = 0; // start time
  var ap = ["AM", "PM"]; // AM-PM

  // loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    timeOptions[i] = {
      label:
        ("0" + (hh % 12 === 0 ? 12 : hh % 12)).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        " " +
        ap[Math.floor(hh / 12)],
      value: ("0" + hh).slice(-2) + ":" + ("0" + mm).slice(-2),
    }; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }

  return timeOptions;
};

export const maxWaitTimePromise = async (timeoutInMilliseconds = 1000) => {
  return new Promise(function (resolve) {
    setTimeout(() => resolve({ isTimeOut: true }), 60 * timeoutInMilliseconds); // this will resolve after 1 min
  });
};

export const isTextEllipsized = (element) => {
  if (!element) {
    return false;
  }

  return (
    element.offsetWidth < element.scrollWidth ||
    element.offsetHeight < element.scrollHeight
  );
};

export const renameTicketToWorkOrder = (str) =>
  str.replace(/ticket/gim, "work order");

export const getWidthInMilimeter = (totalwidth = 0) =>
  totalwidth / PIXELS_PER_MM;

export const retrieveField = (arrayOfObject, field) => {
  return arrayOfObject.reduce((list, current) => {
    current[field] && list.push(current[field]);
    return list;
  }, []);
};

export const convertArrayToObjectArray = (arr) => {
  return arr.map((item) => {
    if (typeof item === "object") {
      if (item.label && item.value) {
        return item;
      }
      return { value: item.value, label: item.value };
    }
    return { value: item, label: item };
  });
};

export function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  } else if (typeof obj === "object" && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      if (key !== "__typename") {
        newObj[key] = removeTypename(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}

export const generateKeyValueObject = (item = "") => {
  return { value: item, label: item };
};

export const generateColorsMap = (customAdditionalFields, teams, user) => {
  const colorsMap = new Map();

  customAdditionalFields
    .filter(
      (customField) =>
        customField.fieldType === "singleSelect" &&
        !!(customField.options || []).length,
    )
    .forEach((customField) => {
      colorsMap.set(customField._id, new Map());
      const customFieldColorMap = colorsMap.get(customField._id);

      customField.options.forEach((option) =>
        customFieldColorMap.set(option.value, option.color),
      );
    });

  teams.forEach((team) => colorsMap.set(team._id, team.teamColor));

  (user.oem.statuses || []).forEach((status) =>
    colorsMap.set(status._id, status.color),
  );

  (user.oem.ticketTypes || []).forEach((tt) => colorsMap.set(tt._id, tt.color));

  return colorsMap;
};

const mergeXAxisEntries = (data, assigneeMap) => {
  const accumulatedData = {};

  data.forEach((entry) => {
    const assigneeName = entry.xAxis;
    const assigneeIds = entry.xAxisId;
    const segment = entry.segment;
    const segmentId = entry.segmentId;

    const key = `${assigneeName}${
      entry.segmentId ? `#${entry.segmentId}` : ""
    }`;

    if (!accumulatedData[key]) {
      accumulatedData[key] = {
        count: 0,
        xAxisId: new Set(),
        segment: segment,
        segmentId: segmentId,
      };
    }

    // Accumulate count
    accumulatedData[key].count += entry.count;

    // Add unique xAxisIds to the Set
    assigneeIds.forEach((id) => accumulatedData[key].xAxisId.add(id));
  });

  // Construct final result
  const result = Object.keys(accumulatedData).map((key) => {
    const assigneeName = key.includes("#") ? key.split("#")[0] : key;
    const uniqueIds = Array.from(accumulatedData[key].xAxisId);

    // Take the first non-empty xAxisId if it exists
    return {
      count: accumulatedData[key].count,
      xAxis: assigneeName,
      xAxisId: uniqueIds.length > 0 ? uniqueIds[0] : "",
      segment: accumulatedData[key].segment,
      segmentId: accumulatedData[key].segmentId,
    };
  });

  return result
    .map((entry) => {
      const mappedId = Object.keys(assigneeMap).find(
        (id) => assigneeMap[id] === entry.xAxis,
      );
      return {
        ...entry,
        xAxisId: mappedId ? mappedId : "",
      };
    })
    .filter((entry) => entry.xAxisId);
};

export const generateChartDataFormattedObjectArray = (
  reportxAxis,
  reportSegment,
  data = [],
  assignees = {},
  colorsMap = new Map(),
) => {
  let result = data;

  if (data?.length && Array.isArray(data?.[0]?.xAxisId)) {
    result = mergeXAxisEntries(data, assignees);
  }

  const segments = [...new Set(result.map((item) => item.segment))];
  const xAxis = [...new Set(result.map((item) => item.xAxis))];
  const xAxisID = [...new Set(result.map((item) => item.xAxisId))];
  const segmentID = [...new Set(result.map((item) => item.segmentId))];

  const xAxisBgColors = xAxisID.map((id) => {
    let color = colorsMap.get(reportxAxis?.value); // custom field

    return typeof color === "object"
      ? colorsMap.get(reportxAxis?.value).get(id)
      : colorsMap.get(id);
  });
  const segmentBgColors = segmentID.map((id) => {
    let color = colorsMap.get(reportSegment?.value); // custom field

    return typeof color === "object"
      ? colorsMap.get(reportSegment?.value).get(id)
      : colorsMap.get(id);
  });

  const defaultBackgroundColors = [
    ...new Set(result.map(() => getRandomColor())),
  ];

  let datasets;

  // Initialize count for each segment
  const counts = {};
  segments.forEach((segment) => {
    counts[segment] = new Array(xAxis.length).fill(0);
  });

  // Populate counts
  result.forEach((item) => {
    const xAxisIndex = xAxis.indexOf(item.xAxis);
    counts[item.segment][xAxisIndex] += item.count;
  });

  // Prepare datasets for Chart.js
  if (segments.length === 1 && segments[0] === undefined) {
    datasets = segments.map((segment) => ({
      label: segment,
      data: counts[segment],
      backgroundColor:
        xAxisBgColors.length > 0 && xAxisBgColors[0] !== undefined
          ? xAxisBgColors
          : defaultBackgroundColors,
    }));
  } else {
    datasets = segments.map((segment, index) => ({
      label: segment,
      id: segmentID[index],
      data: counts[segment],
      backgroundColor:
        segmentBgColors.length > 0 && segmentBgColors[0] !== undefined
          ? segmentBgColors[index]
          : getRandomColor(),
    }));
  }

  // Chart.js configuration
  const chartData = {
    labels: xAxis,
    xAxisId: xAxisID,
    datasets: datasets,
  };

  return chartData;
};

export const getCountsBySegment = (data, assignees) => {
  if (data?.length && Array.isArray(data?.[0]?.xAxisId)) {
    data = mergeXAxisEntries(data, assignees);
  }

  const counts = data.reduce((result, { segment, count }) => {
    if (segment && segment.trim() !== "") {
      if (!result[segment]) {
        result[segment] = 0;
      }
      result[segment] += count;
    }
    return result;
  }, {});

  return Object.entries(counts).map(([segment, count]) => ({ segment, count }));
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const isTrackingDisabled = () => {
  if (sessionStorage.getItem("disableTracking")) {
    return true;
  } else {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const disableTracking = urlSearchParams.get("disableTracking");
    if (disableTracking) {
      sessionStorage.setItem("disableTracking", true);
      return true;
    }
  }
  return false;
};

export const isDescriptionChanged = (oldDescription, newDescription) => {
  const before =
    !oldDescription || typeof oldDescription === "string"
      ? oldDescription
      : JSON.stringify(oldDescription);
  const after =
    !newDescription || typeof newDescription === "string"
      ? newDescription
      : JSON.stringify(newDescription);

  if (
    before === after ||
    (before === "" && after === SLATE_EMPTY_TEXT) ||
    (before === SLATE_EMPTY_TEXT && after === "")
  )
    return false;

  return true;
};

export const getRandomEntry = (dictionary) => {
  const keys = Object.keys(dictionary);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return { [randomKey]: dictionary[randomKey] };
};

export { default as getEnums } from "$/settings/enums";

export * from "./_validations";
export * from "./_sort";
export * from "./_files";
export * from "./_procedures";
export * from "./_customFields";
export * from "./_components";
export * from "./_machines";
export * from "./_parts";
export * from "./_email";
export * from "./_facility";
