import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { useIntl } from "react-intl";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { svgLogo } from "./_svgLogo";
import { LoginForm as Form } from "./form";
import { useAuth } from "./general";
import { useGetOemById } from "../services/auth";

import enumLoginColors from "$/settings/enums/colors/_login.json";
import { LOGIN, LOGIN_FAILED } from "~/constants";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const Login = () => {
  const { messages: msg } = useIntl();
  const { modifier } = useParams();
  const { search } = useLocation();
  const { oem, error } = useGetOemById(modifier);

  const auth = useAuth();
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) {
    const redirectTo = new URLSearchParams(search).get("redirect");
    const pathname = redirectTo ? decodeURIComponent(redirectTo) : "/app";

    return (
      <Redirect
        to={{
          pathname,
          state: {
            welcome: true,
          },
        }}
      />
    );
  }

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <div className={"auth auth--column"}>
        <article className={"auth-side auth-side--login"}>
          <div className="auth-sideHeader">
            <Headline
              size={HEADLINE_SIZES.LARGE}
              color="text-inverse"
              className="max-w-[445px] mb-lg max-md:text-2xl"
            >
              {msg.login?.heroTitle}
            </Headline>
            <BodyText
              size={BODY_TEXT_SIZES.SMALL}
              color="text-inverse"
              className="opacity-80"
            >
              {msg.login?.heroSubtitle}
            </BodyText>
          </div>
          <div className="auth-sideFooter">
            <span className="auth-sideFooterCopy">{msg.login?.poweredBy}</span>
            {svgLogo(oem?.textColor || enumLoginColors.text_color)}
          </div>
        </article>
        <div className="auth-main auth-main--login">
          <div className="o-column">
            <article className="auth-box">
              <Form
                modifier={modifier}
                color={oem?.textColor || enumLoginColors.text_color}
                callback={(values, setError) => {
                  const { email: userEmail } = values;
                  registerMixpanelEvent(LOGIN, {
                    oem: window.location.pathname
                      .split("/")
                      .slice(-1)
                      .join("")
                      .split("?")[0],
                    userEmail,
                  });
                  auth.login(
                    {
                      ...values,
                      organizationId: oem?._id,
                    },
                    (e) => {
                      if (e) {
                        toast(
                          <Toast
                            message={`${msg.login?.wrongCreds}`}
                            type={TOAST_TYPES.ERROR}
                          />,
                          { closeButton: false },
                        );
                        setError("root.random", {
                          type: "random",
                          message: e?.message,
                        });
                        registerMixpanelEvent(LOGIN_FAILED, {
                          oem: window.location.pathname
                            .split("/")
                            .slice(-1)
                            .join("")
                            .split("?")[0],
                          userEmail,
                        });
                      } else {
                        setRedirect(true);
                      }
                    },
                  );
                }}
              />
            </article>
          </div>
        </div>
      </div>
    );
  };

  return renderPage();
};

const ToastLogin = () => <Login />;

export default ToastLogin;
