import ThreeDIconFilled from "@shared/svg/3d-machine-active.svg?react";
import ThreeDIcon from "@shared/svg/3d-machine.svg?react";
import AiAssistantIconFilled from "@shared/svg/ai-assistant-active.svg?react";
import AiAssistantIcon from "@shared/svg/ai-assistant.svg?react";
import AiNotetakerIconFilled from "@shared/svg/ai-notetaker-active.svg?react";
import AiNotetakerIcon from "@shared/svg/ai-notetaker.svg?react";
import CalendarBlankFilled from "@shared/svg/calendar-blank-filled.svg?react";
import CalendarBlank from "@shared/svg/calendar-blank.svg?react";
import ComponentsIconFilled from "@shared/svg/components-filled.svg?react";
import ComponentsIcon from "@shared/svg/components.svg?react";
import DocumentIconFilled from "@shared/svg/documents-filled.svg?react";
import DocumentIcon from "@shared/svg/documents.svg?react";
import EmailIconFilled from "@shared/svg/email-filled.svg?react";
import EmailIcon from "@shared/svg/email.svg?react";
import FolderIconFilled from "@shared/svg/folder-filled.svg?react";
import FolderIcon from "@shared/svg/folder.svg?react";
import GuidesIconFilled from "@shared/svg/guides-active.svg?react";
import GuidesIcon from "@shared/svg/guides.svg?react";
import HourGlassIconFilled from "@shared/svg/hour-glass-active.svg?react";
import HourGlassIcon from "@shared/svg/hour-glass.svg?react";
import InventoryIconFilled from "@shared/svg/inventory-filled.svg?react";
import InventoryIcon from "@shared/svg/inventory.svg?react";
import MachineTemplatesIconFilled from "@shared/svg/machine-templates-active.svg?react";
import MachineTemplatesIcon from "@shared/svg/machine-templates.svg?react";
import MachineIconFilled from "@shared/svg/machines-filled.svg?react";
import MachineIcon from "@shared/svg/machines.svg?react";
import ProcedureIconFilled from "@shared/svg/procedures-filled.svg?react";
import ProcedureIcon from "@shared/svg/procedures.svg?react";
import ProductionLinesIconFilled from "@shared/svg/production-lines-active.svg?react";
import ProductionLinesIcon from "@shared/svg/production-lines.svg?react";
import { getAllRoles } from "@shared/utils/getAllRoles";
import { useIntl } from "react-intl";

import PAID_FEATURES from "$/settings/paid-features.json";
import type { SubRouteType } from "~/types/route";
import type { UserType } from "~/types/user";
import { getEnums } from "~/utils";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const ALL_ROLES = getAllRoles({
  getOEM: true,
  getCRM: false,
  getFacility: false,
});

export const ROUTE_TO_KEY_MAP: { [key: string]: string } = {
  "/app/work/work-orders": "workManagement",
  "/app/assets": "machines",
  "/app/3d/models": "3d",
  "/app/ai": "ai",
};

export const ROUTE_KEY_TO_NOTIFICATION_MAP: { [key: string]: string[] } = {
  workManagement: ["openTickets", "myTickets"],
};

export const SUB_ROUTES: { [key: string]: SubRouteType[] } = {
  "/app/work/work-orders": [
    {
      route: "/app/work/work-orders",
      icon: FolderIcon,
      enableIf: () => true,
      iconActive: FolderIconFilled,
      titleKey: "WORK_ORDERS",
      routeKey: "openTickets",
      title: "Work Orders",
    },
    {
      route: "/app/work/my-work-orders",
      icon: DocumentIcon,
      enableIf: () => true,
      iconActive: DocumentIconFilled,
      titleKey: "MY_WORK_ORDERS",
      routeKey: "myTickets",
      title: "My Work Orders",
    },
    {
      route: "/app/work/procedures",
      icon: ProcedureIcon,
      enableIf: () => true,
      iconActive: ProcedureIconFilled,
      titleKey: "PROCEDURES",
      title: "Procedures",
    },
    {
      route: "/app/work/emails",
      icon: EmailIcon,
      enableIf: (user: UserType) => user.role !== ALL_ROLES.TECHNICIAN.VALUE,
      iconActive: EmailIconFilled,
      titleKey: "EMAILS",
      title: "Emails",
    },
    {
      route: "/app/work/planned-events",
      icon: HourGlassIcon,
      enableIf: () => true,
      iconActive: HourGlassIconFilled,
      titleKey: "PLANNED_EVENTS",
      routeKey: "myPlannedEvents",
      title: "Planned Events",
    },
    {
      route: "/app/work/scheduler",
      icon: CalendarBlank,
      enableIf: (user: UserType) =>
        ![ALL_ROLES.STAFF.VALUE, ALL_ROLES.TECHNICIAN.VALUE].includes(
          user.role,
        ),
      iconActive: CalendarBlankFilled,
      titleKey: "SCHEDULING",
      title: "Scheduler",
    },
  ],
  "/app/assets": [
    {
      route: "/app/assets/all",
      icon: MachineIcon,
      enableIf: (user: UserType) =>
        user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: MachineIconFilled,
      titleKey: "ASSETS",
      title: "Assets",
    },
    {
      route: "/app/assets/production-lines",
      icon: ProductionLinesIcon,
      enableIf: (user: UserType) =>
        !user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: ProductionLinesIconFilled,
      titleKey: "PRODUCTION_LINES",
      title: "Production Lines",
    },
    {
      route: "/app/assets/machines",
      icon: MachineIcon,
      enableIf: (user: UserType) =>
        !user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: MachineIconFilled,
      titleKey: "MACHINES",
      title: "Machines",
    },
    {
      route: "/app/assets/templates",
      icon: MachineTemplatesIcon,
      enableIf: (user: UserType) =>
        user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: MachineTemplatesIconFilled,
      titleKey: "ASSET_TEMPLATES",
      title: "Asset Templates",
    },
    {
      route: "/app/assets/templates",
      icon: MachineTemplatesIcon,
      enableIf: (user: UserType) =>
        !user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: MachineTemplatesIconFilled,
      titleKey: "MACHINE_TEMPLATES",
      title: "Machine Templates",
    },
    {
      route: "/app/assets/components",
      icon: ComponentsIcon,
      enableIf: (user: UserType) =>
        !user.oem.paidFeatures.includes(paidFeatures.hierarchy),
      iconActive: ComponentsIconFilled,
      titleKey: "COMPONENTS",
      title: "Components",
    },
    {
      route: "/app/assets/inventory",
      icon: InventoryIcon,
      enableIf: () => true,
      iconActive: InventoryIconFilled,
      titleKey: "INVENTORY",
      title: "Inventory",
    },
  ],
  "/app/3d/models": [
    {
      route: "/app/3d/models",
      icon: ThreeDIcon,
      enableIf: () => true,
      iconActive: ThreeDIconFilled,
      titleKey: "MODELS",
      title: "Models",
    },
    {
      route: "/app/3d/guides",
      icon: GuidesIcon,
      enableIf: () => true,
      iconActive: GuidesIconFilled,
      titleKey: "GUIDES",
      title: "Guides",
    },
  ],
  "/app/ai": [
    {
      route: "/app/ai/assistant",
      icon: AiAssistantIcon,
      enableIf: () => true,
      iconActive: AiAssistantIconFilled,
      titleKey: "AI_ASSISTANT",
      title: "AI Assistant",
    },
    {
      route: "/app/ai/notetaker",
      icon: AiNotetakerIcon,
      enableIf: () => true,
      iconActive: AiNotetakerIconFilled,
      titleKey: "AI_NOTETAKER",
      title: "AI Notetaker",
    },
  ],
};

export const routeHasSubRoutes = (routeKey: string) => routeKey in SUB_ROUTES;

export const useSubRoutes = (routeKey: string, user?: UserType) => {
  const subRoutes = SUB_ROUTES[routeKey];
  const { messages } = useIntl();
  if (subRoutes) {
    const subMenus = messages?.menus?.[routeKey]?.SUB_MENUS;
    return subRoutes
      .map((subRoute: SubRouteType) => ({
        ...subRoute,
        title: subMenus?.[subRoute.titleKey] || subRoute.title,
      }))
      .filter((subRoute) => subRoute.enableIf(user));
  }
  return subRoutes;
};
