import { WarningCircle } from "phosphor-react";
import React from "react";

type ErrorMessagePropsType = {
  message: string;
};

const ErrorMessage = ({ message }: ErrorMessagePropsType) => {
  return (
    <p className="m-0 flex items-start px-2xs font-manrope font-medium text-xs text-danger no-styles">
      <WarningCircle className="mr-xs text-danger flex-shrink-0" size={16} />
      {message}
    </p>
  );
};

export default ErrorMessage;
