import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_VARIANTS, ButtonVariantType } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import React from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";

export const IMAGE_POSITIONS = {
  TOP: "top",
  CENTER: "center",
} as const;

type ImagePositionType = (typeof IMAGE_POSITIONS)[keyof typeof IMAGE_POSITIONS];

type AlertBoxPropsType = {
  isOpen: boolean;
  portalSelector: string;
  overlay: boolean;
  alertBoxModifiers: string;
  zIndexClass: string;
  wrapperClassName: string;
  image: React.JSX.Element;
  imagePosition: ImagePositionType;
  contentBoxModifiers: string;
  title: string;
  description: string;
  alertContent: React.JSX.Element;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  disableCancelButton: boolean;
  cancelButtonText: string;
  acceptButtonVariant: ButtonVariantType;
  onAccept: React.MouseEventHandler<HTMLButtonElement>;
  disableAcceptButton: boolean;
  acceptButtonText: string;
};

const AlertBox = ({
  isOpen = false,
  portalSelector = null,
  overlay = true,
  alertBoxModifiers = "",
  zIndexClass = "z-20",
  wrapperClassName = "",
  image = null,
  imagePosition = IMAGE_POSITIONS.CENTER,
  contentBoxModifiers = "",
  title = "",
  description = "",
  alertContent = null,
  onCancel = null,
  disableCancelButton = false,
  cancelButtonText = "",
  acceptButtonVariant = BUTTON_VARIANTS.PRIMARY,
  onAccept = null,
  disableAcceptButton = false,
  acceptButtonText = "",
}: Partial<AlertBoxPropsType>) => {
  const { messages } = useIntl();

  if (!isOpen) return null;

  return createPortal(
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${
        overlay ? "bg-secondary/60" : ""
      } ${alertBoxModifiers} ${zIndexClass}`}
    >
      <div
        className={`flex items-center w-[556px] p-2xl bg-primary rounded-lg shadow-alert space-x-sm max-sm:w-full ${wrapperClassName}`}
      >
        {image && (
          <div
            className={`flex justify-center ${
              imagePosition === IMAGE_POSITIONS.CENTER
                ? "items-center"
                : "self-start"
            }`}
          >
            {image}
          </div>
        )}
        <div
          className={`flex flex-col grow justify-between ${
            !alertContent ? "overflow-hidden" : ""
          } ${contentBoxModifiers}`}
        >
          {alertContent || (
            <div className="flex flex-col">
              <Headline size={HEADLINE_SIZES.X_SMALL} className="break-words">
                {title}
              </Headline>
              {description && (
                <BodyText
                  size={BODY_TEXT_SIZES.X_SMALL}
                  color="text-secondary"
                  className="mt-md break-words"
                >
                  {description}
                </BodyText>
              )}
            </div>
          )}
          {onCancel || onAccept ? (
            <div className="flex items-center justify-end mt-2xl">
              {onCancel && (
                <Button
                  variant={BUTTON_VARIANTS.OUTLINE}
                  text={cancelButtonText || messages?.common["no"]}
                  disabled={disableCancelButton}
                  onClick={onCancel}
                />
              )}

              {onAccept && (
                <Button
                  variant={acceptButtonVariant}
                  text={acceptButtonText || messages?.common["yes"]}
                  disabled={disableAcceptButton}
                  className="ml-md"
                  onClick={onAccept}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>,
    document.querySelector(portalSelector || ".alert-box-modal-portal"),
  );
};

export default AlertBox;
