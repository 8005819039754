export const BREADCRUMB_DEFAULT_TEXT_LIMIT: number = 30;

// Data Table
export const ITEMS_BEFORE_PAGE = 2;
export const TABLE_VIEW_ROW_HEIGHT = 42;
export const TABLE_VIEW_COLUMN_SIZE_SUFFIX = "_TABLE_COLUMN_SIZES";

export const TOAST_AUTO_CLOSE_DURATION = 3000;

export const TEXT_ALIGN_TYPES: string[] = [
  "left",
  "center",
  "right",
  "justify",
];

export const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const DATE_TIME_FORMAT = "YYYY-MM-DD hh:mm";
