import * as React from "react";
const SvgGuides = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M17.5 4.168v9.72c0 .718-.46 1.355-1.14 1.582l-5.833 1.944a1.667 1.667 0 0 1-1.054 0L3.64 15.47a1.667 1.667 0 0 1-1.14-1.581V4.168a1.667 1.667 0 0 1 2.194-1.581L9.473 4.18c.342.114.712.114 1.054 0l4.78-1.593A1.667 1.667 0 0 1 17.5 4.168Z",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M10.003 7.5v6.667",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgGuides;
