import React from "react";

type InputLabelPropsType = {
  label: string;
  keyId?: string;
  uppercaseLabel?: boolean;
};

const InputLabel = ({
  label,
  keyId = "",
  uppercaseLabel = false,
}: InputLabelPropsType) => {
  return (
    <label
      htmlFor={keyId}
      className={`font-manrope font-medium text-xs text-secondary ${
        keyId ? "cursor-pointer" : ""
      } ${uppercaseLabel ? "uppercase" : ""}`}
    >
      {label}
    </label>
  );
};

export default InputLabel;
