import * as React from "react";
const SvgTeamsFilter = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: 16,
  height: 16,
  viewBox: "0 0 16 16",
  fill: "none",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M13.8616 6.34903C14.3883 6.87571 14.3883 7.72964 13.8616 8.25633C13.335 8.78301 12.481 8.78301 11.9543 8.25633C11.4277 7.72964 11.4277 6.87571 11.9543 6.34903C12.481 5.82234 13.335 5.82234 13.8616 6.34903",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M9.46843 3.94158C10.2794 4.75257 10.2794 6.06744 9.46843 6.87844C8.65745 7.68942 7.34257 7.68942 6.53158 6.87844C5.72059 6.06745 5.72059 4.75258 6.53158 3.94158C7.34257 3.1306 8.65744 3.1306 9.46843 3.94158",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M4.04572 6.34903C4.57241 6.87571 4.57241 7.72964 4.04572 8.25633C3.51904 8.78301 2.66511 8.78301 2.13842 8.25633C1.61174 7.72964 1.61174 6.87571 2.13842 6.34903C2.66511 5.82234 3.51904 5.82234 4.04572 6.34903",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M15.3335 12.6667V11.936C15.3335 11.0153 14.5875 10.2693 13.6668 10.2693H13.1328",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M0.666748 12.6667V11.936C0.666748 11.0153 1.41275 10.2693 2.33341 10.2693H2.86741",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M11.5595 12.6667V11.5994C11.5595 10.3107 10.5149 9.26602 9.22619 9.26602H6.77352C5.48485 9.26602 4.44019 10.3107 4.44019 11.5994V12.6667",
  stroke: "#748094",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgTeamsFilter;
