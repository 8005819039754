import "~/tailwind.css";

import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { TOAST_AUTO_CLOSE_DURATION } from "@shared/constants/index";
import Tooltip from "@shared/ui/Tooltip";
import LogRocket from "logrocket";
import "react-toastify/dist/ReactToastify.min.css";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

import ROUTES_SETTINGS from "$/settings/routes-resolvers.json";
import { apolloClient } from "~/apollo";
import App from "~/App";
import { Login } from "~/components";
import { ErrorBoundary, ErrorPage } from "~/components/general";
import AuthPageGuard from "~/components/general/AuthPageGuard";
import ForgotPassword from "~/containers/_forgotPassword";
import ForgotPasswordSuccess from "~/containers/_forgotPasswordSuccess";
import LanguageProvider from "~/lang/provider";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

import "~/index.css";

if (isMixpanelEnabled()) {
  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_TOKEN, { debug: true });
}

const { CLIENT: { ROUTES: { OEM_LOGIN: LOGIN } = {} } = {} } = ROUTES_SETTINGS;

ReactDOM.render(
  <React.StrictMode>
    <ApolloHooksProvider client={apolloClient}>
      <IntercomProvider
        appId={import.meta.env.VITE_APP_INTERCOM_APP_ID}
        autoBoot
      >
        <RecoilRoot>
          <RecoilNexus />
          <LanguageProvider>
            <ErrorBoundary>
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path={`${LOGIN}/:modifier?`}
                    render={() => (
                      <AuthPageGuard>
                        <Login />
                      </AuthPageGuard>
                    )}
                  />
                  <Route
                    exact
                    path="/forgot-password/:modifier?"
                    render={() => (
                      <AuthPageGuard>
                        <ForgotPassword />
                      </AuthPageGuard>
                    )}
                  />
                  <Route
                    exact
                    path="/forgot-password-success/:modifier?"
                    render={() => (
                      <AuthPageGuard>
                        <ForgotPasswordSuccess />
                      </AuthPageGuard>
                    )}
                  />
                  <Route exact path="/" render={() => <Redirect to="/app" />} />
                  <Route path="/app" component={App} />
                  <Route
                    exact
                    path="/error-page/:error"
                    component={ErrorPage}
                  />
                  <Route component={ErrorPage} />
                </Switch>
                <ToastContainer
                  className="toast-container message-container"
                  toastClassName="message"
                  draggable={false}
                  hideProgressBar
                  autoClose={TOAST_AUTO_CLOSE_DURATION}
                  position="bottom-right"
                />
                <Tooltip id="global-tooltip" />
              </BrowserRouter>
            </ErrorBoundary>
          </LanguageProvider>
        </RecoilRoot>
      </IntercomProvider>
    </ApolloHooksProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
