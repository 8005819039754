import * as React from "react";
const SvgProcedures = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 21 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "m15.678 5.845-2.356-2.357A1.667 1.667 0 0 0 12.143 3H6.167C5.246 3 4.5 3.746 4.5 4.667v11.666c0 .921.746 1.667 1.667 1.667H14.5c.92 0 1.667-.746 1.667-1.667v-9.31c0-.441-.176-.865-.489-1.178v0Z",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M16.167 7.167h-3.334A.834.834 0 0 1 12 6.333V3M7 12.167h5M7 14.667h3.608",
  stroke: "currentColor",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgProcedures;
