import { Input } from "@shared/ui/Inputs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

const ForgotPasswordForm = ({ callback = () => {} }) => {
  const { messages: msg } = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(false);
    callback(data);
  };

  const onError = () => {
    setLoading(false);
  };

  const emailField = register("email", { required: true });

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <Input
        keyId="email"
        label={msg?.login?.forgotPassword.email.label}
        name="email"
        type="email"
        placeholder={msg?.login?.forgotPassword.email.placeholder}
        {...emailField}
        setRef={emailField.ref}
        errorMessage={
          errors?.email && msg?.login?.forgotPassword.email.invalidEmail
        }
        customInputClasses={
          errors?.email
            ? "bg-primary border-danger focus:bg-primary focus:border-danger"
            : watch("email")
            ? "bg-primary border-primary"
            : "bg-accent border-transparent"
        }
      />
      <div className="form-group auth-submit forget-password-btn">
        <button
          className={`c-button c-button--primary primary-icon-btn-v2 ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? `${msg?.login?.forgotPassword.resetLoading}`
            : `${msg?.login?.forgotPassword.reset}`}
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
