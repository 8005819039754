import COLOR_PALETTE from "$/settings/color-palette.json";

export const mapColorsFromPalette = (
  keyName = "primary",
  valueName = "light",
) => {
  return Object.keys(COLOR_PALETTE).reduce((accumulator, current) => {
    accumulator[COLOR_PALETTE[current][keyName]] =
      COLOR_PALETTE[current][valueName];
    return accumulator;
  }, {});
};
