import * as React from "react";
const SvgAiAssistantActive = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M7.14 11.25h3.211M10.625 12.084l-1.35-4.167h-1.05l-1.35 4.167M12.917 12.084V7.917",
  stroke: "#0517f8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}), /* @__PURE__ */ React.createElement("path", {
  clipRule: "evenodd",
  d: "M2.5 10a7.46 7.46 0 0 0 1.359 4.297l-.527 2.37 2.37-.527A7.496 7.496 0 1 0 2.5 10v0Z",
  stroke: "#0517f8",
  strokeWidth: 1.2,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgAiAssistantActive;
