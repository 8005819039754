import Toast, { ToastType } from "@shared/ui/Toast";
import React, { useRef } from "react";
import { ToastOptions, toast } from "react-toastify";

import COLORS from "$/settings/tailwind/colorPrimitives";

export const toastProgressStyles = {
  // backgroundColor: `${COLORS["green"][400]}25`,
  backgroundColor: "transparent",
  height: "0px",
};

const useUpdatableToast = () => {
  const toastId = useRef(null);

  const startToast = (
    msg: React.ReactNode,
    type: ToastType,
    autoClose: number | false = false,
    options: ToastOptions = {},
  ) => {
    toastId.current = toast(<Toast message={msg} type={type} />, {
      autoClose: autoClose,
      progress: 0,
      progressStyle: toastProgressStyles,
      closeButton: false,
      ...options,
      hideProgressBar: true,
    });
  };

  const updateToast = (
    message: React.ReactNode,
    type: ToastType,
    autoClose: number | false = false,
    options: ToastOptions = {},
  ) => {
    toast.update(toastId.current, {
      render: <Toast message={message} type={type} />,
      autoClose: autoClose,
      progressStyle: toastProgressStyles,
      closeButton: false,
      ...options,
      hideProgressBar: true,
    });
  };

  const endToast = () => {
    toast.done(toastId.current);
    return (toastId.current = toast.dismiss(toastId.current));
  };

  return { startToast, updateToast, endToast, toastId };
};

export default useUpdatableToast;
