import ReactTooltip, { Effect, GetContent, Offset, Place } from "react-tooltip";

type TooltipPropsType = {
  id: string;
  className?: string;
  effect?: Effect;
  place?: Place;
  ariaHasPopup?: string;
  afterShow?: () => void;
  getContent?: GetContent;
  offset?: Offset;
};

const PLACEMENT_STYLES: Record<Place, string> = {
  top: "after:!-bottom-2xs",
  bottom: "after:!-top-2xs",
  left: "after:!-right-2xs after:!-mt-[3px]",
  right: "after:!-left-2xs after:!-mt-[3px]",
};

const Tooltip = ({
  id,
  className = "",
  effect = "solid",
  place = "bottom",
  ariaHasPopup = "",
  afterShow = () => {},
  getContent = null,
  offset = null,
}: TooltipPropsType) => {
  return (
    <ReactTooltip
      id={id}
      className={`!px-sm !py-2xs !font-manrope !text-xs !font-medium !text-inverse !bg-secondary !rounded-md !opacity-100 after:!w-xs after:!h-xs after:!bg-secondary after:!rounded-[1px] before:hidden ${PLACEMENT_STYLES[place]} ${className}`}
      effect={effect}
      place={place}
      afterShow={afterShow}
      aria-haspopup={ariaHasPopup}
      getContent={getContent}
      offset={offset}
    />
  );
};

export default Tooltip;
