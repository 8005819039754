import { useQuery } from "@apollo/client";

import {
  LIST_ADDITIONAL_FIELDS_QUERY,
  LIST_OWN_OEM_CUSTOM_ADDITIONAL_FIELDS_QUERY,
} from "../../api";

export const useAdditionalFieldsList = (type) => {
  const {
    data: { listAdditionalFields: additionalFields = [] } = {},
    error,
    loading,
  } = useQuery(LIST_ADDITIONAL_FIELDS_QUERY, {
    variables: { type },
  });

  return {
    additionalFields,
    error,
    loading,
  };
};

export const useListOwnOemCustomFields = ({
  type,
  skipCondition = false,
} = {}) => {
  const {
    data: { listOwnOemCustomFields: customAdditionalFields = [] } = {},
    loading,
    error,
    refetch,
  } = useQuery(LIST_OWN_OEM_CUSTOM_ADDITIONAL_FIELDS_QUERY, {
    variables: { type },
    skip: skipCondition,
  });

  return {
    customAdditionalFields,
    loading,
    error,
    refetch,
  };
};
