import * as React from "react";
const SvgReloadPage = (props) => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 221 220",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#0C266D",
  d: "m136.833 105.147-.129.137 14.661 13.712.129-.137-14.661-13.712Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E8EAED",
  d: "M196.115 43.305H24.885a4.52 4.52 0 0 0-4.529 4.513V148.09a4.52 4.52 0 0 0 4.529 4.513h171.23a4.52 4.52 0 0 0 4.528-4.513V47.818a4.52 4.52 0 0 0-4.528-4.513Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M196.115 153.916H24.885a5.863 5.863 0 0 1-4.135-1.709 5.823 5.823 0 0 1-1.714-4.121V47.829a5.824 5.824 0 0 1 1.714-4.12A5.863 5.863 0 0 1 24.885 42h171.23a5.864 5.864 0 0 1 4.135 1.709 5.826 5.826 0 0 1 1.714 4.12v100.269a5.822 5.822 0 0 1-1.718 4.113 5.861 5.861 0 0 1-4.131 1.705ZM24.885 44.621a3.216 3.216 0 0 0-2.267.938c-.601.6-.94 1.412-.94 2.26v100.267c0 .848.339 1.661.94 2.26a3.217 3.217 0 0 0 2.267.937h171.23a3.216 3.216 0 0 0 2.267-.937c.601-.599.94-1.412.941-2.26V47.829a3.197 3.197 0 0 0-.941-2.259 3.215 3.215 0 0 0-2.267-.937l-171.23-.012Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#0517F8",
  d: "M35.012 56.807a2.486 2.486 0 0 0 2.49-2.482 2.486 2.486 0 0 0-2.49-2.482 2.486 2.486 0 0 0-2.49 2.482 2.486 2.486 0 0 0 2.49 2.482Zm8.642 0a2.486 2.486 0 0 0 2.49-2.482 2.486 2.486 0 0 0-2.49-2.482 2.486 2.486 0 0 0-2.49 2.482 2.486 2.486 0 0 0 2.49 2.482Zm9.244 0a2.486 2.486 0 0 0 2.49-2.482 2.486 2.486 0 0 0-2.49-2.482 2.486 2.486 0 0 0-2.49 2.482 2.486 2.486 0 0 0 2.49 2.482Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M209.16 175H11.84v-10.234a5.825 5.825 0 0 1 1.714-4.121 5.862 5.862 0 0 1 4.135-1.708H203.31a5.86 5.86 0 0 1 4.134 1.708 5.824 5.824 0 0 1 1.715 4.121V175Zm-194.679-2.633h192.037v-7.601a3.195 3.195 0 0 0-.94-2.259 3.22 3.22 0 0 0-2.267-.937H17.689a3.217 3.217 0 0 0-2.267.937 3.195 3.195 0 0 0-.94 2.259v7.601Zm76.389-37.055a5.324 5.324 0 0 1-3.336-1.219 6.55 6.55 0 0 1-2.302-3.983l-11.15-62.748a6.69 6.69 0 0 1 .792-4.514 5.34 5.34 0 0 1 3.562-2.516l22.81-4.039 22.641 17.3 8.581 48.141a6.224 6.224 0 0 1-.378 4.596 4.81 4.81 0 0 1-4.026 2.516l-36.311 6.394c-.292.05-.588.074-.883.072Zm9.89-77.022-21.996 3.896a3.428 3.428 0 0 0-2.264 1.629 4.876 4.876 0 0 0-.55 3.23l11.139 62.749a4.664 4.664 0 0 0 1.63 2.843 3.376 3.376 0 0 0 2.698.753l36.47-6.439h.083a2.943 2.943 0 0 0 1.434-.443 2.923 2.923 0 0 0 1.026-1.092 4.379 4.379 0 0 0 .234-3.189l-.034-.124-8.471-47.479L100.76 58.29Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "m140.272 130.17-41.76 7.372a5.254 5.254 0 0 1-3.926-.864 5.235 5.235 0 0 1-2.16-3.379l-11.17-62.763a5.218 5.218 0 0 1 .87-3.913 5.245 5.245 0 0 1 3.39-2.154l25.701-4.55 24.753 16.326 8.536 47.836a4.754 4.754 0 0 1-.445 3.949c-1.28 2.031-3.623 2.118-3.789 2.14Z"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#0517F8",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M106.377 99.404h-5.28v-5.28m6.29 21.718a12.659 12.659 0 0 0 4.166 1.516m-9.778-8.206a12.794 12.794 0 0 0 2.211 3.845m12 4.361a12.672 12.672 0 0 0 4.167-1.516m3.401-2.845a12.77 12.77 0 0 0 2.21-3.845"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#0517F8",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 3,
  d: "M126.443 104.684c0-7-5.673-12.673-12.673-12.673-5.112 0-9.506 3.033-11.51 7.393"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M97.567 138.546a6.22 6.22 0 0 1-3.971-1.451 6.184 6.184 0 0 1-2.119-3.649l-11.15-62.745a6.164 6.164 0 0 1 1.03-4.607 6.202 6.202 0 0 1 3.992-2.538l26.078-4.615 25.41 16.759 8.592 48.152a5.698 5.698 0 0 1-.566 4.682c-1.445 2.279-3.958 2.528-4.509 2.554l-41.692 7.357a6.374 6.374 0 0 1-1.095.101Zm13.449-77.638-25.339 4.487a4.314 4.314 0 0 0-2.78 1.767 4.282 4.282 0 0 0-.71 3.209l11.15 62.744a4.276 4.276 0 0 0 1.775 2.772c.464.323.987.552 1.54.674a4.326 4.326 0 0 0 1.681.033l41.829-7.383h.075a3.908 3.908 0 0 0 3.019-1.681 3.752 3.752 0 0 0 .347-3.14l-.034-.143-8.449-47.441-24.104-15.898Z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#CDD1FE",
  d: "M135.178 76.681c-6.762 1.271-12.075 1.94-14.887 2.088-.966.048-2.641.078-4.007-.884a5.335 5.335 0 0 1-2.124-3.114c-.034-.14-.053-.249-.064-.301v-.034c-.019-.106-.993-4.085-3.363-13.702"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#172B4D",
  d: "M119.619 79.728a6.616 6.616 0 0 1-3.875-1.083 6.24 6.24 0 0 1-2.589-4.021c-.041-.184-.653-2.7-3.358-13.675l1.834-.451c3.339 13.562 3.362 13.686 3.396 13.761.034.076.03.166.056.278a4.377 4.377 0 0 0 1.763 2.566c1.188.827 2.75.752 3.418.714 3.212-.165 8.732-.94 14.762-2.072l.351 1.85c-6.113 1.129-11.724 1.933-15.018 2.099a8.142 8.142 0 0 1-.74.034Z"
}));
export default SvgReloadPage;
