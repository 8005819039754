import React from "react";

import { useAuth } from "../components/general";
import { FACILITES } from "../constants/filters/scopes";
import { useListOwnOemCustomFields } from "../services";

import getEnums from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features.json";
import { customFieldTypes } from "~/constants/filters/customFieldTypes";
import {
  ASSIGNEE,
  AssigneeFilterObj,
  DATE_RANGE,
  DateRangeFilterObj,
  FACILITY_NAME,
  FacilityFilterObj,
  MACHINE_ASSIGNED,
  MACHINE_NAME,
  MACHINE_QR_CODE_ACCESS,
  MachineAssignedFilterObj,
  MachineFilterObj,
  MachineQRAccessFilterObj,
  TeamsFilterObj,
  SCHEDULED,
  ScheduleFilterObj,
  TEAMS,
  TICKET_STATUS,
  TICKET_TYPE,
  TicketStatusFilterObj,
  TicketTypeFilterObj,
  ASSET_ASSIGNED,
  ASSET_NAME,
  ASSET_QR_CODE_ACCESS,
  ASSET_TYPE,
  AssetAssignedFilterObj,
  AssetFilterObj,
  AssetQRAccessFilterObj,
  AssetTypeFilterObj,
} from "~/constants/filters/filters";
import {
  MACHINES,
  MY_WORK_ORDER,
  WORK_ORDER,
} from "~/constants/filters/scopes";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const SELECTED_FILTERS_SUFIX = "_SELECTED_FILTERS";
const ACTIVE_FILTERS_SUFIX = "_ACTIVE_FILTERS";

const getDefaultFilters = (
  scope = WORK_ORDER,
  isTeamsPaid = false,
  isAsset = false,
) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return [
        TicketStatusFilterObj,
        AssigneeFilterObj,
        ScheduleFilterObj,
        TicketTypeFilterObj,
        FacilityFilterObj,
        ...(isAsset ? [AssetFilterObj] : [MachineFilterObj]),
        DateRangeFilterObj,
        ...(isTeamsPaid ? [TeamsFilterObj] : []),
      ];

    case MACHINES:
      return [
        ...(isAsset ? [AssetAssignedFilterObj] : [MachineAssignedFilterObj]),
        FacilityFilterObj,
        ...(isAsset ? [AssetQRAccessFilterObj] : [MachineQRAccessFilterObj]),
        ...(isAsset ? [AssetTypeFilterObj] : []),
        ...(isTeamsPaid ? [TeamsFilterObj] : []),
      ];

    case FACILITES:
      return isTeamsPaid ? [TeamsFilterObj] : [];

    default:
      return [];
  }
};

const getInitialActiveFilters = (scope = WORK_ORDER, isAsset = false) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return {
        [TICKET_STATUS]: [],
        [ASSIGNEE]: [],
        [TICKET_TYPE]: [],
        [FACILITY_NAME]: [],
        ...(isAsset ? { [ASSET_NAME]: [] } : { [MACHINE_NAME]: [] }),
        [DATE_RANGE]: [],
        [SCHEDULED]: [],
        [TEAMS]: [],
      };

    case MACHINES:
      return {
        ...(isAsset ? { [ASSET_ASSIGNED]: [] } : { [MACHINE_ASSIGNED]: [] }),
        [FACILITY_NAME]: [],
        ...(isAsset
          ? { [ASSET_QR_CODE_ACCESS]: [] }
          : { [MACHINE_QR_CODE_ACCESS]: [] }),
        ...(isAsset ? { [ASSET_TYPE]: [] } : {}),
        [TEAMS]: [],
      };

    case FACILITES:
      return {
        ...(isAsset ? { [ASSET_ASSIGNED]: [] } : { [MACHINE_ASSIGNED]: [] }),
        [TEAMS]: [],
      };

    default:
      return {};
  }
};

const getInitialToggleFilters = (scope = WORK_ORDER, isAsset = false) => {
  switch (scope) {
    case WORK_ORDER:
    case MY_WORK_ORDER:
      return {
        [TICKET_STATUS]: {
          open: false,
        },
        [ASSIGNEE]: {
          open: false,
        },
        [TICKET_TYPE]: {
          open: false,
        },
        [FACILITY_NAME]: {
          open: false,
        },
        ...(isAsset
          ? { [ASSET_NAME]: { open: false } }
          : { [MACHINE_NAME]: { open: false } }),
        [DATE_RANGE]: {
          open: false,
        },
        [SCHEDULED]: {
          open: false,
        },
        [TEAMS]: {
          open: false,
        },
      };

    case MACHINES:
      return {
        ...(isAsset
          ? { [ASSET_ASSIGNED]: { open: false } }
          : { [MACHINE_ASSIGNED]: { open: false } }),
        [FACILITY_NAME]: { open: false },
        ...(isAsset
          ? { [ASSET_QR_CODE_ACCESS]: { open: false } }
          : { [MACHINE_QR_CODE_ACCESS]: { open: false } }),
        ...(isAsset ? { open: false } : {}),
        [TEAMS]: {
          open: false,
        },
      };

    case FACILITES:
      return {
        ...(isAsset
          ? { [ASSET_ASSIGNED]: { open: false } }
          : { [MACHINE_ASSIGNED]: { open: false } }),
        [TEAMS]: {
          open: false,
        },
      };

    default:
      return {};
  }
};

const determineFilterObj = (id) => {
  switch (id) {
    case TICKET_STATUS:
      return TicketStatusFilterObj;
    case ASSIGNEE:
      return AssigneeFilterObj;
    case SCHEDULED:
      return ScheduleFilterObj;
    case TICKET_TYPE:
      return TicketTypeFilterObj;
    case FACILITY_NAME:
      return FacilityFilterObj;
    case MACHINE_NAME:
      return MachineFilterObj;
    case DATE_RANGE:
      return DateRangeFilterObj;
    case MACHINE_ASSIGNED:
      return MachineAssignedFilterObj;
    case MACHINE_QR_CODE_ACCESS:
      return MachineQRAccessFilterObj;
    case TEAMS:
      return TeamsFilterObj;
    case ASSET_NAME:
      return AssetFilterObj;
    case ASSET_ASSIGNED:
      return AssetAssignedFilterObj;
    case ASSET_QR_CODE_ACCESS:
      return AssetQRAccessFilterObj;
    case ASSET_TYPE:
      return AssetTypeFilterObj;
    default:
      return null;
  }
};

const useFilter = (
  scope = WORK_ORDER,
  baseFilters,
  customFieldsFilterScope = Object.values(customFieldTypes),
  isAsset = false,
) => {
  const { user } = useAuth();
  const isTeamsPaid = user.oem.paidFeatures.includes(paidFeatures.teams);
  const selectedFilterKey = React.useMemo(
    () => scope + SELECTED_FILTERS_SUFIX,
    [],
  );
  const activeFilterKey = React.useMemo(() => scope + ACTIVE_FILTERS_SUFIX, []);

  const [selectedFilters, setSelectedFilters] = React.useState({ value: [] });
  const [unselectedFilters, setUnselectedFilters] = React.useState(() => {
    if (!baseFilters) {
      baseFilters = getDefaultFilters(scope, isTeamsPaid, isAsset);
    }
    return {
      value: baseFilters,
    };
  });
  const [activeFilters, setActiveFilters] = React.useState(() =>
    getInitialActiveFilters(scope, isAsset),
  );

  const [toggleFilters, setToggleFilters] = React.useState(() =>
    getInitialToggleFilters(scope, isAsset),
  );

  const {
    customAdditionalFields = [],
    loading: customAdditionalFieldsLoading,
  } = useListOwnOemCustomFields();

  const machinesCustomAdditionalFields = [];
  const facilitiesCustomAdditionalFields = [];
  const ticketsCustomAdditionalFields = [];

  customAdditionalFields?.forEach((field) => {
    if (field.type === customFieldTypes.ticket) {
      ticketsCustomAdditionalFields.push(field);
    }
    if (field.type === customFieldTypes.facilities) {
      facilitiesCustomAdditionalFields.push(field);
    }
    if (field.type === customFieldTypes.machines) {
      machinesCustomAdditionalFields.push(field);
    }
  });

  const updateSelectedFilters = (customFieldFilters) => {
    const selectedFilterIds = JSON.parse(
      localStorage.getItem(selectedFilterKey) || "[]",
    );

    if (selectedFilterIds.length > 0) {
      const newSelectedFilters = [];

      for (const id of selectedFilterIds) {
        const filterObj =
          determineFilterObj(id) ||
          customFieldFilters.find((i) => i._id === id);

        if (filterObj) {
          newSelectedFilters.push(filterObj);
        }
      }

      if (newSelectedFilters.length > 0) {
        setSelectedFilters(() => ({ value: newSelectedFilters }));
      }
    }
    return selectedFilterIds;
  };

  const updateUnselectedFilters = (
    allCustomFieldFilters,
    selectedFilterIds,
  ) => {
    let newUnselectedFilters = [
      ...(unselectedFilters?.value || []),
      ...allCustomFieldFilters,
    ];

    if (selectedFilterIds.length > 0) {
      newUnselectedFilters = newUnselectedFilters.filter(
        (filter) => !selectedFilterIds.includes(filter._id || filter.id),
      );
    }

    setUnselectedFilters({
      value: newUnselectedFilters,
    });
  };

  const updateActiveFilters = (allCustomFieldFilters) => {
    const active = {};
    const persistentActiveFilters = JSON.parse(
      localStorage.getItem(activeFilterKey) ?? "{}",
    );

    allCustomFieldFilters.forEach((e) => {
      active[e._id] = [];
    });

    const newActiveFilters = {
      ...activeFilters,
      ...active,
      ...persistentActiveFilters,
    };

    setActiveFilters(newActiveFilters);
  };

  const updateDropdownToggles = (allCustomFieldFilters) => {
    const dropdownToggle = {};

    allCustomFieldFilters.forEach((e) => {
      dropdownToggle[e._id] = { open: false };
    });

    setToggleFilters((prev) => ({ ...prev, ...dropdownToggle }));
  };

  const handleSelectFilter = (value) => {
    let filterIds = [];

    for (const item of value.value) {
      filterIds.push(item._id ?? item.id);
    }

    localStorage.setItem(selectedFilterKey, JSON.stringify(filterIds));
    setSelectedFilters(value);
  };

  const handleUnselectFilter = (value) => {
    setUnselectedFilters(value);
  };

  const handleActiveFilter = (activeFilter) => {
    const value = {};

    for (const id of Object.keys(activeFilter)) {
      if (
        activeFilter[id].length > 0 ||
        typeof activeFilter[id] === "boolean"
      ) {
        value[id] = activeFilter[id];
      }
    }

    localStorage.setItem(activeFilterKey, JSON.stringify(value));
    setActiveFilters(activeFilter);
  };

  // custom and additional fields work
  React.useEffect(() => {
    let isMount = true;

    const filteredFacilitiesCustomAdditionalFields =
      facilitiesCustomAdditionalFields?.filter(
        (field) => field?.fieldType !== "address",
      );

    if (
      (machinesCustomAdditionalFields.length > 0 ||
        filteredFacilitiesCustomAdditionalFields.length > 0 ||
        ticketsCustomAdditionalFields.length > 0) &&
      !customAdditionalFieldsLoading &&
      isMount
    ) {
      const machineCF = Array.isArray(machinesCustomAdditionalFields)
        ? machinesCustomAdditionalFields
        : [];
      const facilityCF = Array.isArray(filteredFacilitiesCustomAdditionalFields)
        ? filteredFacilitiesCustomAdditionalFields
        : [];
      const ticketCF = Array.isArray(ticketsCustomAdditionalFields)
        ? ticketsCustomAdditionalFields
        : [];

      let allCustomFieldFilters = [];
      if (customFieldsFilterScope.includes(customFieldTypes.machines)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...machineCF];
      }
      if (customFieldsFilterScope.includes(customFieldTypes.facilities)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...facilityCF];
      }
      if (customFieldsFilterScope.includes(customFieldTypes.ticket)) {
        allCustomFieldFilters = [...allCustomFieldFilters, ...ticketCF];
      }

      const selectedFilterIds = updateSelectedFilters(allCustomFieldFilters);

      updateUnselectedFilters(allCustomFieldFilters, selectedFilterIds);
      updateActiveFilters(allCustomFieldFilters);
      updateDropdownToggles(allCustomFieldFilters);
    }

    return () => {
      isMount = false;
    };
  }, [
    machinesCustomAdditionalFields.length,
    facilitiesCustomAdditionalFields.length,
    ticketsCustomAdditionalFields.length,
  ]);

  return {
    selectedFilters,
    unselectedFilters,
    activeFilters,
    toggleFilters,
    handleSelectFilter,
    handleUnselectFilter,
    handleActiveFilter,
    setToggleFilters,
    scope,
  };
};

export default useFilter;
