import React from "react";

export const LABEL_SIZES = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
  X_SMALL: "xSmall",
} as const;

type SizeType = (typeof LABEL_SIZES)[keyof typeof LABEL_SIZES];

type LabelProps = {
  children: React.ReactNode;
  color: string;
  setRef?: (_: HTMLDivElement) => void;
  size: SizeType;
};

const SIZE_STYLES: Record<SizeType, string> = {
  [LABEL_SIZES.LARGE]: "text-base",
  [LABEL_SIZES.MEDIUM]: "text-sm",
  [LABEL_SIZES.SMALL]: "text-xs",
  [LABEL_SIZES.X_SMALL]: "text-2xs",
};

const Label = ({
  size = LABEL_SIZES.LARGE,
  color = "text-primary",
  className = "",
  children,
  setRef,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & Partial<LabelProps>) => {
  const classes = `font-manrope font-bold ${SIZE_STYLES[size]} ${color} ${className}`;

  return (
    <div className={classes} {...props} ref={setRef}>
      {children}
    </div>
  );
};

export default Label;
